import React from "react";


// reactstrap components
import {
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";

import "./style.css";

function PageNavigation(props) {

    const pagesCount = props.pageCount;
    const currentPage = props.page; 
    const setCurrentPage = props.setPage;

    
    return (  
 
        <Pagination size="lg" aria-label="Media Library" style={{"justifyContent": "center", "margin":"30px"}}>
            
            <PaginationItem disabled={currentPage <= 0}>
              
              <PaginationLink
                onClick={e => setCurrentPage(currentPage - 1)}
                previous
              />
              
            </PaginationItem>

            {[...Array(pagesCount)].map((page, i) => 
              <PaginationItem active={i === currentPage} key={i}>
                <PaginationLink onClick={e => setCurrentPage(i)}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            )}

            <PaginationItem disabled={currentPage >= pagesCount - 1}>
              
              <PaginationLink
                onClick={e => setCurrentPage(currentPage + 1)}
                next
              />
              
            </PaginationItem>
            
        </Pagination>

    );
}

export default PageNavigation;


