import useUser from "components/Authentication/useUser";
import Analytics from "components/Analytics/Analytics";


function AnalyticPage(props) {
    
    const {user} = useUser();

    return (  
        <div className="content">
            {user && 
                <Analytics></Analytics>
            }
        </div>
    );
}

export default AnalyticPage;