
import React from "react";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";


import { usage_data, usage_options } from "./UsageData";
import { word_data, word_options } from "./WordData";
import { request_analytics } from "services/Services";

const { useState, useEffect } = React

function Analytics(props) {

    const [analytics, setAnalytics] = useState();

    useEffect(() => {

      request_analytics({
            onSucceed: setAnalytics
        })
      
    }, []);

    
    return (  
      <>

        {/* {analytics &&
          <pre>{JSON.stringify(analytics, null, 2)}</pre> 
        } */}

        <Row>
            <Col md="12">
                <Card className="card-chart">
                <CardHeader>
                    <Row>
                        <Col className="text-left" sm="6">
                            <h5 className="card-category">Number of Media</h5>
                            <CardTitle tag="h3">
                                <i className="tim-icons icon-triangle-right-17" /> {" "} {analytics?.['number_of_media']}
                            </CardTitle>
                        </Col>

                        <Col className="text-right" sm="6">
                            <h5 className="card-category">Total Duration</h5>
                            <CardTitle tag="h3">
                                <i className="tim-icons icon-planet" /> {" "} {analytics?.['total_duration']} 
                            </CardTitle>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="chart-area">
                    <Line
                        data={usage_data(analytics?.['usage'])}
                        options={usage_options}
                    />
                    </div>
                </CardBody>
                </Card>
          </Col>
{/* 
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Media List</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Language</th>
                      <th>Duration</th>
                      <th>Date Added</th>
                      <th className="text-center">Filtered Words</th>
                      <th className="text-right">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Record1</td>
                      <td>Turkish</td>
                      <td>13:23</td>
                      <td>02.04.2023 12:34</td>
                      <td className="text-center"></td>
                      <td className="text-right"><p className="text-warning">Pending</p></td>
                    </tr>
                    <tr>
                      <td>Record2</td>
                      <td>English</td>
                      <td>05:10</td>
                      <td>02.04.2023 12:34</td>
                      <td className="text-center"></td>
                      <td className="text-right"><p className="text-info">Not Started</p></td>
                    </tr>
                    <tr>
                      <td>Record3</td>
                      <td>Turkish</td>
                      <td>03:23</td>
                      <td>02.04.2023 12:34</td>
                      <td className="text-center"></td>
                      <td className="text-right"><p className="text-success">Completed</p></td>
                    </tr>
                    <tr>
                      <td>Record4</td>
                      <td>German</td>
                      <td>05:43</td>
                      <td>02.04.2023 12:34</td>
                      <td className="text-center"><i className="tim-icons icon-check-2"/></td>
                      <td className="text-right"><p className="text-success">Completed</p></td>
                    </tr>
                    <tr>
                      <td>Record5</td>
                      <td>Arabic</td>
                      <td>01:24</td>
                      <td>02.04.2023 12:34</td>
                      <td className="text-center"></td>
                      <td className="text-right"><p className="text-danger">Failed</p></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col> */}

         
          <Col xl="12">
            <Card className="card-chart" style={{height: '720px'}}>
              <CardHeader>
                  <Row>
                      <Col className="text-left" sm="6">
                        <h5 className="card-category">Word Count</h5>
                        <CardTitle tag="h3">
                          <i className="tim-icons icon-caps-small" />{" "}
                          {analytics?.['number_of_words']} words
                        </CardTitle>
                      </Col>

                      <Col className="text-right" sm="6">
                          <h5 className="card-category">Filtered Words</h5>
                          <CardTitle tag="h3">
                              <i className="tim-icons icon-pencil" /> {" "}
                              {analytics?.['number_of_filtered_words']} words 
                          </CardTitle>
                      </Col>
                    </Row>



                
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Bar
                    data={word_data(analytics?.['word_counts'])}
                    options={word_options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </>
    );
}

export default Analytics;