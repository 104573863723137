import React from 'react';

import { IconWrapper, IconStyle } from "./WaveSurferPlayer.styled";

// Icons
import {
    HiMiniPlay,
    HiMiniPause
  } from 'react-icons/hi2';
  
import {
    PiRecordFill,
    PiStopCircleFill
} from 'react-icons/pi';

import {
    // TiTick,
    TiRefresh
} from 'react-icons/ti'

const PlayPauseButton = ({ isPlaying, onPlayClick }) => {
    return (
      <IconWrapper onClick={onPlayClick}>
        <HiMiniPause
          style={{
            ...IconStyle,
            opacity: isPlaying ? 1 : 0,
            pointerEvents: isPlaying ? 'auto' : 'none', // Prevent clicking the hidden icon
          }}
        />
        <HiMiniPlay
          style={{
            ...IconStyle,
            opacity: isPlaying ? 0 : 1,
            pointerEvents: isPlaying ? 'none' : 'auto', // Prevent clicking the hidden icon
          }}
        />
      </IconWrapper>
    );
  };

const RecordStopButton = ({ isRecording, onRecordClick }) => {
    return (
      <IconWrapper onClick={onRecordClick}>
        <PiStopCircleFill
          style={{
            ...IconStyle,
            opacity: isRecording ? 1 : 0,
            pointerEvents: isRecording ? 'auto' : 'none', // Prevent clicking the hidden icon
          }}
        />
        <PiRecordFill
          style={{
            ...IconStyle,
            opacity: isRecording ? 0 : 1,
            pointerEvents: isRecording ? 'none' : 'auto', // Prevent clicking the hidden icon
          }}
        />
      </IconWrapper>
    );
  };
  


const ControlButtons = ({ children, canPlay, recorderEnabled, isPlaying, isRecording, onPlayClick, onRecordClick, onResetClick }) => {
    return (
        <>
            {canPlay 
                ? <PlayPauseButton isPlaying={isPlaying} onPlayClick={onPlayClick}/>
                : <RecordStopButton isRecording={isRecording} onRecordClick={onRecordClick}/>
            }

            {children}

            {recorderEnabled &&
                <IconWrapper onClick={onResetClick} >
                    <TiRefresh style={IconStyle} />
                </IconWrapper>
            }
        </>
    );
};

export default ControlButtons;