/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

import UserProfile from "components/Profile/UserProfile";
import WordFilterInput from "components/WordFilter/WordFilterInput";

function UserProfilePage() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <UserProfile/>
          </Col>
          <Col md="4">
            <WordFilterInput/>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default UserProfilePage;
