import React from "react";

import useUser from "components/Authentication/useUser";

import MediaUpload from "components/MediaUpload/MediaUpload";
import MediaList from "components/MediaList/MediaList";
import Media from "components/Media/Media";


// Import React hooks
const { useState } = React


function MediaLibrary(props) {
    
    const {user} = useUser();
    
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [newTranscription, setNewTranscription] = useState(0);

    return (  
        <>

            <div className="content">

                {user &&
                    <>
                    
                        {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}

                        {selectedMedia
                            ? <>

                                <Media selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia} />                                

                            </>
                            : <>
                                <MediaUpload id="media-uploader" setNewTranscription={setNewTranscription}></MediaUpload>
                                
                                <MediaList newTranscription={newTranscription} setSelectedMedia={setSelectedMedia}></MediaList>
                            </>
                        }                    
                    </>
 
                
            }

            </div>
        </>
    );
}

export default MediaLibrary;