import React, { useState, useEffect } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
  } from "reactstrap";

import { TagsInput } from "react-tag-input-component";
import "./style.css";

import { request_word_list } from "services/Services";
import { request_word_update } from "services/Services";


function WordFilterInput(props) {

    const [wordList, setWordList] = useState([]);

    useEffect(() => {
        request_word_list({
            onSucceed: (response => setWordList(response['word_list']))
        })
    }, []);


    const onWordsChanged = (word_list) => {
        request_word_update({
            data : { 'word_list': word_list }
        })
    }
    
    return (  
        <>

        <Card>
            <CardHeader>
                <CardTitle tag="h2">Add words to filter transcripts</CardTitle> 
            </CardHeader>

            <CardBody>

                <TagsInput
                        value={wordList}
                        onChange={onWordsChanged}
                        name="words"
                        placeHolder="enter words"
                    />
                    
                <em>press enter to add new tag</em>

            </CardBody>

        </Card>
  
        </>
    );
}

export default WordFilterInput;