import React, { useState, useEffect } from "react";

import Segment from "./Segment";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row, Col
  } from "reactstrap";
import Editor from "components/Editor/Editor";

import { request_word_list } from "services/Services";


function Transcript(props) {

    const media = props.media
    const playerReference = props.playerReference

    const [wordList, setWordList] = useState([]);

    useEffect(() => {
        request_word_list({
            onSucceed: (response => {
                response['word_list'].sort(function(a, b){
                    return b.length - a.length;
                  });
                setWordList(response['word_list']);
            })
        })
    }, []);

    return (  
        <Card>
            <CardHeader>
                <Row style={{justifyContent:"space-between"}}>
                    <Col>
                        <CardTitle tag="h3">Transcription</CardTitle>
                    </Col>
                    <Col style={{display:"flex", justifyContent:"flex-end"}}>
                        <Editor mediaID={media['_id']}></Editor>
                    </Col>
                </Row>
            </CardHeader>
        
            <CardBody>

                    {media['transcript']['segments'].map((segment, index)=>{
                        return <Segment key={index} 
                            media={media['_id']}
                            segment={segment} 
                            playerReference={playerReference}
                            wordList={wordList}/>
                        })}
            </CardBody>
        
        </Card> 
             
    );
}

export default Transcript;