import React, { useState, useEffect } from "react";
import validator from 'validator'

import {
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Row,
    Col,
  } from "reactstrap";

function ChangePassword({newPassword, setNewPassword, confirmPassword, setConfirmPassword, canChangePassword}) {
    
    const [isValid, setIsValid] = useState(false)
    const [isMatched, setIsMatched] = useState(false)
    
    useEffect(() => {

        if(!newPassword && !confirmPassword) {
            setIsValid(true);
            setIsMatched(true);
            canChangePassword.current = true;
            return
        }
        
        const passwordValid = validator.isStrongPassword(newPassword, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })

        const passwordMatched = (newPassword === confirmPassword)

        canChangePassword.current = (passwordValid && passwordMatched);

        setIsValid(passwordValid);
        setIsMatched(passwordMatched);
        
    }, [newPassword, confirmPassword, canChangePassword]);
   
    return (  
        <>
            <CardHeader>
            <h5 className="title">Change Password</h5>
            </CardHeader>
            <CardBody>           
                <Row>
                    <Col className="pr-md-1" md="6">
                    <FormGroup>
                        <label>New Password</label>
                        <Input
                            placeholder="New Password"
                            type="password"
                            onChange={evt => setNewPassword(evt.target.value)}
                            required={isValid === false}
                        />
                        {!isValid && <p className="text-warning" style={{fontSize:11}}>Password must be at least 8 characters including upper/lowercase, number and symbol! </p>}
                    </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="6">
                    <FormGroup>
                        <label>Confirm New Password</label>
                        <Input
                            placeholder="Confirm New Password"
                            type="password"
                            onChange={evt => setConfirmPassword(evt.target.value)}
                            required={isMatched === false}
                        />
                        {!isMatched && <p className="text-warning" style={{fontSize:11}}>Passwords does not match!</p>}
                    </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </>
    );
}

export default ChangePassword;