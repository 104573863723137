import React from "react";
import { Fade, Tooltip } from 'reactstrap';
import MouseTooltip from 'react-sticky-mouse-tooltip';

import Moment from 'moment';

import ContentEditable from 'react-contenteditable'

import "./style.css";
import { request_media_segment } from "services/Services";

// Import React hooks
const { useState, useRef, useEffect } = React


function Segment(props) {

    const reference = useRef(null);

    var updateTimeout;
  
    const playerReference = props.playerReference;

    const segment = props.segment
    const language = props.language === undefined ? null : props.language.toLowerCase();
    const speaker = segment?.['speaker']

    const content = useRef(language === null ? segment['edit'] : segment['translation'][language]);

    const [isInSegment, setIsInSegment] = useState(false);

    const [refreshContent, setRefreshContent] = useState(false);
    const [showTimestamp, setShowTimestamp] = useState(false);
    const [showTranscribedText, setShowTranscribedText] = useState(false);

    const [isEdited, setIsEdited] = useState(segment['text'] !== segment['edit']);

    if(segment['text'] !== segment['edit']) {
      console.log('diff!', segment['id']);
    }

    //-------------------------------------------//

    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        if (!playerReference.current) {
            setRefresh(!refresh)
            return
        }
    
        const subscriptions = [
            playerReference.current.on('timeupdate', (currentTime) => {
                setIsInSegment(currentTime >= segment['start'] && currentTime <= segment['end'])
            }),
        ]
    
        return () => {
          try{ subscriptions.forEach((unsub) => unsub()) }
          catch(e) { /* pass */ }
        }
      }, [refresh, playerReference, segment])


    useEffect(() => {
      if (language) {
        content.current = segment['translation'][language];
        setRefreshContent(state => !state);
      }
    }, [language])

    // useEffect(() => {
    //     if(language)
    //       return;
    //     if(isInSegment) 
    //       reference.current.scrollIntoView({  behavior: "auto", block: "center", inline: "nearest" });
    // }, [isInSegment])
    //-------------------------------------------//
    
    const playSegment = () => {
        if(isInSegment) return
        playerReference.current.setTime(segment['start'])
        playerReference.current.play()
    }

    //-------------------------------------------//

    const onSegmentChange = (value) => {
    
      content.current = value;

      clearTimeout(updateTimeout)
      updateTimeout = setTimeout(() => {
        request_media_segment({
          data: { 'media_id': props.media,
                  'segments': { [segment['id']]: value },
                  'language' : language
                },
          onSucceed: (() => { setIsEdited(segment['text'] !== value) })
        })
      }, 200)
      
    }


    //-------------------------------------------//

    const onContextMenu = (event) => {
       event.preventDefault();
       setShowTranscribedText(true);
    }

    const onRestoreSegment = () => {
      if(language)
        return;

      onSegmentChange(segment['text']);
      setIsEdited(false);
      setRefreshContent(state => !state);
    }


    //-------------------------------------------//
    
    const textToHtml = (text) => {
      if(props.wordList === undefined)
        return text
      
      props.wordList.forEach((word) => { 
        let regex = new RegExp('('+word+')', "gi")
        text = text.replace(regex, '<span class="filtered-word">$1</span>');
      })

      return text
    }

    const htmlToText = (html) => {
      var temp = document.createElement('div');
      temp.innerHTML = html;
      return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
    }

    //-------------------------------------------//

    const editedClass = isEdited ? 'edited' : '';
    const statusClass = isInSegment ? 'active' : 'normal';

    return (  
        <>
          <span onClick={playSegment} onContextMenu={(e) => onContextMenu(e)} id={`tooltip-${segment['id']}-${language}`} 
            onMouseEnter={() => setShowTimestamp(true)}  onMouseLeave={() => setShowTimestamp(false)}
            ref={reference}>

              {/* {speaker && <span>{speaker}:</span>} */}

              <ContentEditable
                  className={`segment ${editedClass} ${statusClass} ${speaker}`} 
                  html={textToHtml(content.current)}      // innerHTML of the editable div
                  disabled={false}       // use true to disable editing
                  onChange={evt => onSegmentChange(htmlToText(evt.target.value))} // handle innerHTML change
                  tagName={speaker ? 'div' : 'span'} // Use a custom HTML tag (uses a div by default)
                  onBlur={() => setShowTranscribedText(false)}
              />
          </span>


          <MouseTooltip
            visible={showTimestamp}
            offsetX={15}
            offsetY={-25}
          >

            <Fade in={showTimestamp}>
              <div className="tooltip-container" >
                {Moment.utc(segment['start']*1000).format('mm:ss.SSS')} - {Moment.utc(segment['end']*1000).format('mm:ss.SSS')}
              </div>
            </Fade>

          </MouseTooltip>

          <Tooltip placement="top" isOpen={showTranscribedText} target={`tooltip-${segment['id']}-${language}`} 
              onClick={onRestoreSegment}>
            {segment['text']}
          </Tooltip>

        </>
    );
}

export default Segment;