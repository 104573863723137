import React from "react";

import {
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Row,
    Col,
  } from "reactstrap";

function UpdateProfile({
        username, setUsername, 
        email, setEmail, 
        firstName, setFirstName, 
        lastName, setLastName,
        isUsernameExist, setIsUsernameExist,
        isEmailExist, setIsEmailExist
      }) {

   
    return (  
        <>
          <CardHeader>
            <h5 className="title">Edit Profile</h5>
          </CardHeader>
            <CardBody>
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Username</label>
                      <Input
                        defaultValue={username}
                        placeholder="Username"
                        type="text"
                        onChange={evt => {
                          setUsername(evt.target.value);
                          setIsUsernameExist(false);
                        }}
                        required={true}
                      />
                      {isUsernameExist && <p className="text-warning" style={{fontSize:11}}>Username already exists!</p>}
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label htmlFor="exampleEmail">
                        Email address
                      </label>
                      <Input
                        defaultValue={email}
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder="example@mail.com"
                        onChange={evt => {
                          setEmail(evt.target.value);
                          setIsEmailExist(false)
                        }}
                        required={true}
                      />
                      {isEmailExist && <p className="text-warning" style={{fontSize:11}}>Email already exists!</p>}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>First Name</label>
                      <Input
                        defaultValue={firstName}
                        placeholder="First Name"
                        type="text"
                        onChange={evt => setFirstName(evt.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    <FormGroup>
                      <label>Last Name</label>
                      <Input
                        defaultValue={lastName}
                        placeholder="Last Name"
                        type="text"
                        onChange={evt => setLastName(evt.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
            </CardBody>
        </>
    );
}

export default UpdateProfile;