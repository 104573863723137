
const DBNAME = 'kiwidb'

// Function to open (or create) a database
function openDatabase() {
  return new Promise((resolve, reject) => {
      const request = indexedDB.open(DBNAME, 1);

      request.onupgradeneeded = function(event) {
          const db = event.target.result;
          db.createObjectStore('data', { keyPath: 'key' });
      };

      request.onsuccess = function(event) {
          const db = event.target.result;
          resolve(db);
      };

      request.onerror = function(event) {
          reject(event.target.error);
      };
  });
}


// Function to set data in IndexedDB
export async function setData(key, value) {
  try {
      const db = await openDatabase();
      const transaction = db.transaction(['data'], 'readwrite');
      const objectStore = transaction.objectStore('data');
      objectStore.put({ key, value });
  } catch (error) {
      console.error('Error setting data:', error);
  }
}


// Function to get data from IndexedDB
export async function getData(key) {
  try {
      const db = await openDatabase();
      const transaction = db.transaction(['data'], 'readonly');
      const objectStore = transaction.objectStore('data');
      const request = objectStore.get(key);
      return new Promise((resolve, reject) => {
          request.onsuccess = function(event) {
              const result = event.target.result;
              if (result) {
                  resolve(result.value);
              } else {
                  resolve(null); // Data not found
              }
          };
          request.onerror = function(event) {
              reject(event.target.error);
          };
      });
  } catch (error) {
      console.error('Error getting data:', error);
      return null;
  }
}

// Function to delete the IndexedDB database
export function deleteDatabase() {
  indexedDB.deleteDatabase(DBNAME);
}

// Event listener for beforeunload (window is about to close)
window.addEventListener('beforeunload', () => {
    deleteDatabase();
});
