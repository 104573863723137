/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import LoginPage from "views/LoginPage";
import MediaLibrary from "views/MediaLibrary.js";
import AnalyticPage from "views/AnalyticPage.js";
import UserProfilePage from "views/UserProfile.js";

var routes = [

  {
    path: "/media",
    name: "Media Library",
    rtlName: "",
    icon: "tim-icons icon-book-bookmark",
    component: <MediaLibrary />,
    layout: "",
  },
  {
    path: "/analytics",
    name: "Analytics",
    rtlName: "",
    icon: "tim-icons icon-zoom-split",
    component: <AnalyticPage />,
    layout: "",
  },
  {
    path: "/profile",
    name: "User Profile",
    rtlName: "",
    icon: "tim-icons icon-single-02",
    component: <UserProfilePage />,
    layout: "",
  },
  {
    path: "/login",
    name: "Logout",
    rtlName: "",
    icon: "tim-icons icon-button-power",
    component: <LoginPage />,
    layout: "",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "",
  //   icon: "tim-icons icon-book-bookmark",
  //   component: <Icons />,
  //   layout: "",
  // },
];
export default routes;
