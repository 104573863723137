import React from "react";

import BarLoader from "react-spinners/BarLoader";

import PageNavigation from "components/Pagination/Pagination";

import MediaItem from "./MediaItem";

import { request_media_list } from "services/Services";
import MediaFilter from "./MediaFilter";

// Import React hooks
const { useState, useEffect } = React


function MediaList(props) {
    
    const numberOfPageItem = 5;
    
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [mediaList, setMediaList] = useState(null);

    const [language, setLanguage] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const onDataReceived = (data) => {
        setMediaList(data['media_list'])
        setPageCount(Math.ceil(data['number_of_media'] / numberOfPageItem))
    }

    useEffect(() => {
      request_media_list({
        data: { 'skip': numberOfPageItem * page,
                'limit': numberOfPageItem,
                'language': language !== "All" ? language : null,
                'from_date': fromDate,
                'to_date': toDate
              },
        onSucceed: onDataReceived
      })

    }, [props.newTranscription, page, language, fromDate, toDate]);

    
    return (  
        <>

            <PageNavigation pageCount={pageCount} page={page} setPage={setPage} />

            <MediaFilter 
                setLanguage={setLanguage} 
                setFromDate={setFromDate}
                setToDate={setToDate}
            />

            {mediaList 
            
            ? <div>
                
                {mediaList.map((media, index)=>{
                    return <MediaItem 
                    key={index}
                    media={media} 
                    setSelectedMedia={props.setSelectedMedia} />
                })}
                
            
                </div> 
        
            : <BarLoader
                color="rgb(196, 196, 196)"
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
                width="100%"
            
            /> }

                

        </>
    );
}

export default MediaList;