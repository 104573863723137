import React from "react";

import BarLoader from "react-spinners/BarLoader";

import WaveSurferPlayer from "./WaveSurferPlayer";
import ContextMenu from "./ContextMenu";

// Import React hooks
const { useRef } = React


export const WavePlayer = (props) => {

    // Context Menu
    const contextMenuContainerRef = useRef()

    return (  
        <div ref={contextMenuContainerRef}>
            {props.audio ?
                <>
                    <WaveSurferPlayer 
                        height={90}
                        waveColor="rgb(196, 196, 196)"
                        progressColor="rgb(254, 110, 0)"
                        url={props.audio}
                        startTime={0}
                        barWidth={3}
                        barRadius={3}
                        barGap={2}
                        barMinHeight={1}
                        responsive={true}
                        cursorWidth={1}
                        cursorColor="transparent"
                        playerReference={props.playerReference}
                    />

                    <ContextMenu
                        name={props.name}
                        url={props.audio}
                        containerRef={contextMenuContainerRef}
                    />
                </>
                : <BarLoader
                    color="rgb(196, 196, 196)"
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    width="100%"
                /> 
            }

        </div>
             
    );
}

export const WaveRecorder = (props) => {

    return (
        <WaveSurferPlayer
            height={90}
            waveColor="rgb(196, 196, 196)"
            progressColor="rgb(254, 110, 0)"
            url={""}
            startTime={0}
            barWidth={3}
            barRadius={3}
            barGap={2}
            barMinHeight={1}
            responsive={true}
            cursorWidth={1}
            cursorColor="transparent"
            onRecordComplete={props.onRecordComplete}
        />
    );
}
