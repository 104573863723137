import React from "react";


import ContentEditable from 'react-contenteditable'

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button
  } from "reactstrap";

import MediaTabs from "./Tabs";

import { WavePlayer } from "components/WaveSurfer/Wave";
import { request_media_detail, request_media_audio } from "services/Services";
import { request_media_delete } from "services/Services";

import confirm from "reactstrap-confirm";
import { request_media_update } from "services/Services";


  // Import React hooks
const { useState, useEffect, useRef } = React


function Media(props) {

    const topRef = useRef(null);

    var updateTimeout;
    
    const playerReference = useRef()

    const media_id = props.selectedMedia
    const [media, setMedia] = useState(null)
    const [audio, setAudio] = useState(null)

    useEffect(() => {

        request_media_detail({
            data : { 'media_id': media_id },
            onSucceed: setMedia
        })

        request_media_audio({
            data : { 'media_id': media_id },
            onSucceed: setAudio
        })

    }, [media_id]);

    useEffect(() => {
        topRef.current.scrollIntoView({ });
    }, []);
    

    const onDeleteMedia = async () => {

        let result = await confirm({
            title: (
                <>
                    <strong>Delete Media</strong>!
                </>
            ),
            message: "Do you really want to delete media?",
            confirmText: "Delete media!",
            confirmColor: "danger",
            cancelColor: "secondary",
        });

        if(result) {
            request_media_delete({
                data : { 'media_id': media_id },
                onSucceed: (() => props.setSelectedMedia(null))
            })
        }
    }

    const onMediaNameChanged = (value) => {
        if (value) {
            media['name'] = value;
            clearTimeout(updateTimeout)
            updateTimeout = setTimeout(() => {
                request_media_update({
                    data : { 
                        'media_id': media_id,
                        'name': value
                    },
                })
            }, 200)
        }
    }

    let holdAlt = false;
    document.addEventListener('keydown', function(event){
        if(event.key === 'Alt') {
            holdAlt = true
        }
        
        if(holdAlt && event.key.toLowerCase() === 'r') {
            playerReference.current?.playPause()
        }
    })

    document.addEventListener('keyup', function(event){
        if(event.key === 'Alt') {
            holdAlt = false
        }
    })

    return (  
        <>
            <div ref={topRef} style={{marginTop:"-80px", height:"80px"}}></div>
            
            { media && <>
                <Card style={{zIndex:4}}>
                    <Row style={{justifyContent:"space-between"}}>
                        <Col style={{paddingLeft:"40px",  paddingTop:"8px", paddingBottom:"8px"}}>
                            <Button onClick={() => props.setSelectedMedia(null)}>
                            <i className="tim-icons icon-double-left" style={{margin:"-5px 10px 0px 0px"}}/>
                                Back
                            </Button>
                        </Col>
                        <Col style={{paddingRight:"40px",  paddingTop:"8px", paddingBottom:"8px", display:"flex", justifyContent:"flex-end"}}>
                            <Button onClick={onDeleteMedia}>
                                Delete
                                <i className="tim-icons icon-trash-simple" style={{margin:"-5px 0px 0px 10px"}}/> 
                            </Button>
                        </Col>
                    </Row>
                </Card>
                
                <Card className="player-container">
                    <CardHeader>
                        <CardTitle >
                            <ContentEditable
                                html={media['name']}      // innerHTML of the editable div
                                disabled={false}       // use true to disable editing
                                onChange={evt => onMediaNameChanged(evt.target.value)} // handle innerHTML change
                                tagName='h2' // Use a custom HTML tag (uses a div by default)
                            />
                            </CardTitle>
                    </CardHeader>

                    <CardBody>
                        <Row>
                            
                            {/* <Col md="3">
                                <CardText><i className="tim-icons icon-world" /> {capitalize(media['transcript']['language'])} </CardText>
                                <CardText><i className="tim-icons icon-watch-time" /> {Moment.utc(media['duration']*1000).format('mm:ss')} </CardText>
                                <CardText><i className="tim-icons icon-calendar-60" /> {Moment.utc(media['created']).local().format('D MMM YYYY, HH:mm')} </CardText>
                            </Col> */}

                            
                            <Col md="12">
                                <WavePlayer audio={audio} name={media['name']} playerReference={playerReference}/>
                            </Col>
                        </Row>

                    </CardBody>

                </Card>
                
                <MediaTabs media={media} playerReference={playerReference} />

                {/* <Transcript media={media} playerReference={playerReference} />
                
                <Translation media={media} playerReference={playerReference} />

                <Analysis media={media} /> */}

                {/* {['turkish', 'english', 'arabic']
                    .includes(media['transcript']['language'].toLowerCase()) &&
                    <Translation media={media} playerReference={playerReference} />
                } */}
                                
                {/* <pre>{JSON.stringify(media, null, 2)}</pre> */}

            </>
            
            }
                
        </>
    );
}

export default Media;