const toolbar = [
    [
        "bold", "italic", "underline", "strike",
    ],
    [
        {"align": []},
        {"indent": "-1"},
        {"indent": "+1"},
    ],
    [
        {"list": "ordered"},
        {"list": "bullet"},
    ],

    [
        {"background": []},
        {"color": []},
    ],

    [
        {"header": 1},
        {"header": 2},
        {"header": [1, 2, 3, false]},
    ],
    [
        "link", "image"
    ]
]

export default toolbar;