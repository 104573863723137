import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import Transcript from "./Transcript";
import Translation from "./Translation";
import Summary from "./Summary";
import Analysis from "./Analysis";

export default class MediaTabs extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };

    this.is_experimental = this.props.media['transcript']['language'].includes('bambara');
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {
    return (
      <div>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Transcript
            </NavLink>
          </NavItem>

          {!this.is_experimental && (<>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
              >
                Translate
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '3' })}
                onClick={() => { this.toggle('3'); }}
              >
                Summary
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '4' })}
                onClick={() => { this.toggle('4'); }}
              >
                Analyze
              </NavLink>
            </NavItem>
          </>)}
        </Nav>


        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Transcript media={this.props.media} playerReference={this.props.playerReference} />
          </TabPane>
          {!this.is_experimental && (<>
            <TabPane tabId="2">
              <Translation media={this.props.media} playerReference={this.props.playerReference} />
            </TabPane>
            <TabPane tabId="3">
              <Summary media={this.props.media} />
            </TabPane>
            <TabPane tabId="4">
              <Analysis media={this.props.media} />
            </TabPane>
          </>)}
        </TabContent>
      </div>
    );
  }
}