import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";

import Moment from 'moment';

import { WavePlayer } from "components/WaveSurfer/Wave";

import { request_media_audio } from "services/Services";

// Import React hooks
const { useState, useEffect } = React

function MediaItem(props) {

    const media = props.media; 
    const isRunning = (media['status']['transcript'] === 'running');
    const language = media['transcript']?.['language'].charAt(0).toUpperCase() + media['transcript']?.['language'].slice(1);
    const duration = Moment.utc(media['duration']*1000).format('mm:ss');
    const date = Moment.utc(media['created']).local().format('D MMM YYYY, HH:mm');

    
    const onClick = (e) => {
        if(!isRunning)
            props.setSelectedMedia(media['_id']);
    }

    const onWaveClick = (e) => {
        e.stopPropagation();
    }

    const [audio, setAudio] = useState(null)

    useEffect(() => {

        request_media_audio({
            data : { 'media_id': media['_id'] },
            onSucceed: setAudio
        })
      
    }, [media]);

    return(    
        <Card onClick={onClick}>
            <CardHeader>
                <Row>
                    <Col className="text-left" md="6">
                        <CardTitle tag="h2">{media['name']}</CardTitle>    
                    </Col>
                    <Col className="text-right" md="6">
                        {media['filtered_words'] > 0 &&
                            <CardTitle className="text-warning">Filtered words: {media['filtered_words']}</CardTitle>    
                        }
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>

            <Row>
                <Col md="3">
                    {/* <CardTitle>Special title treatment</CardTitle> */}
                    
                    {isRunning 
                        ? <p className="text-info">Transcribing...</p>
                        : <CardText><i className="tim-icons icon-world" /> &nbsp; {language}</CardText>
                    }

                    <CardText><i className="tim-icons icon-watch-time" /> &nbsp; {duration} </CardText>
                    <CardText><i className="tim-icons icon-calendar-60" /> &nbsp; {date} </CardText>
                </Col>

                
                <Col md="8" onClick={onWaveClick}>
                    <WavePlayer audio={audio} name={media['name']} />
                </Col>
            </Row>
                    
            </CardBody>
        </Card>
    )
}

export default MediaItem