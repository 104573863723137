import React from "react";

import {
    Label,
    Input,
    Row, Col
  } from "reactstrap";

import { DatePicker } from 'reactstrap-date-picker'

import "./style.css";

// Import React hooks
const { useState } = React


function MediaFilter(props) {

    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const [toDate, setToDate]= useState(date.toISOString())

    date.setMonth(date.getMonth() - 1);
    const [fromDate, setFromDate]= useState(date.toISOString())
    
    return (  
        <Row>
            <Col>
                <Label>Language</Label>
                <Input type="select" name="select" id="language" onChange={(event) => props.setLanguage(event.target.value)} >
                    <option>All</option>
                    <option>Turkish</option>
                    <option>English</option>
                    <option>German</option>
                    <option>Spanish</option>
                    <option>French</option>
                    <option>Arabic</option>
                    <option>Hebrew</option>
                    <option>Bambara (experimental)</option>
                </Input>
            </Col>

            <Col>
                <Label>Date From</Label>
                <DatePicker id = "from-datepicker" 
                    value={fromDate} 
                    onChange= {(value, formatted) => {
                        if(formatted) {
                            setFromDate(value);
                            props.setFromDate(value);
                        }
                        else {
                            props.setFromDate(null);
                        }
                    }} />
            </Col>

            <Col>
                <Label>Date To</Label>
                <DatePicker id = "to-datepicker"
                    value={toDate}  
                    onChange={(value, formatted) => {
                        if(formatted) {
                            let date = new Date(value);
                            date.setDate(date.getDate() + 1);
                            date.setHours(0, 0, 0, 0);

                            setToDate(date.toISOString());
                            props.setToDate(date.toISOString());
                        }
                        else {
                            props.setToDate(null);
                        }
                    }} />
            </Col>
        </Row>
    );
}

export default MediaFilter;