import React from 'react';

import { TimeDisplayContainer, TimeText, Separator, MonospaceCharacter } from "./WaveSurferPlayer.styled";


const TimeDisplay = ({ currentTime, totalTime }) => {

  const formatTime = (seconds) => {
      const formattedTime = [seconds / 60, seconds % 60]
        .map((v) => `0${Math.floor(v)}`.slice(-2))
        .join(':');
    
      // Split the formatted time into individual characters and wrap each in a MonospaceCharacter component
      return formattedTime.split('').map((char, index) => (
        <MonospaceCharacter key={index}>{char}</MonospaceCharacter>
      ));
    };

  return (
    <TimeDisplayContainer enablehover={totalTime}>
      <TimeText className="alwaysShow currentTime">{formatTime(currentTime)}</TimeText>
      <Separator className="hideable" />
      <TimeText className="hideable">{formatTime(totalTime)}</TimeText>
    </TimeDisplayContainer>
  );

};

export default TimeDisplay;
