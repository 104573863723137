import React from "react";

// Import WaveSurfer
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record";

import TimeDisplay from "./TimeDisplay";
import ControlButtons from "./ControlButtons";

import { WavePlayerContainer, WaveformContainer } from "./WaveSurferPlayer.styled";

// Import React hooks
const { useRef, useState, useEffect, useCallback } = React

// WaveSurfer hook
const useWavesurfer = (containerRef, options) => {
  const [wavesurfer, setWavesurfer] = useState(null)

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!containerRef.current) return

    const ws = WaveSurfer.create({
      ...options,
      container: containerRef.current,
    })

    ws.on('ready', () => {
      ws.setTime(options.startTime)
    })

    setWavesurfer(ws)

    return () => {
      ws.destroy()
    }
  }, [options, containerRef])

  return wavesurfer
}

// WaveRecorder hook
const useRecorder = (wavesurfer, isRecorderEnabled) => {
  const [recorder, setRecorder] = useState(null)

  useEffect(() => {
    if (!isRecorderEnabled || !wavesurfer) return
    // Initialize the Record plugin
    setRecorder(wavesurfer.registerPlugin(RecordPlugin.create()))
  }, [wavesurfer, isRecorderEnabled])

  return recorder
}


// Create a React component that will render wavesurfer.
// Props are wavesurfer options.
const WaveSurferPlayer = (props) => {
  
  // Wavesurfer //----------------------------------------------------------------------
  const containerRef = useRef()
  const [canPlay, setCanPlay] = useState(props.url)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playTime, setPlayTime] = useState(0.0)
  const [audioDuration, setAudioDuration] = useState(0.0)
  const wavesurfer = useWavesurfer(containerRef, props)

  // On play button click
  const onPlayClick = useCallback(() => {
    wavesurfer.playPause()
  }, [wavesurfer])

  // Initialize wavesurfer when the container mounts
  // or any of the props change
  useEffect(() => {
    if (!wavesurfer) return

    if(props.playerReference !== undefined) {
      props.playerReference.current = wavesurfer;
    }

    // Set defaults
    setIsPlaying(false)
    setPlayTime(0.0)
    setAudioDuration(0.0)

    const subscriptions = [
      wavesurfer.on('ready', () => setAudioDuration(wavesurfer.getDuration())),
      wavesurfer.on('play', () => setIsPlaying(true)),
      wavesurfer.on('pause', () => setIsPlaying(false)),
      wavesurfer.on('timeupdate', (currentTime) => setPlayTime(currentTime)),
    ]

    return () => {
      try{ subscriptions.forEach((unsub) => unsub()) }
      catch(e) { /* pass */ }
    }
  }, [wavesurfer, props.playerReference])

  // Waverecoder //----------------------------------------------------------------------
  const [isRecorderEnabled] = useState(!props.url)
  const [isRecording, setIsRecording] = useState(false)
  const [recordTime, setRecordTime] = useState(0.0)

  const recorder = useRecorder(wavesurfer, isRecorderEnabled)
  
  // On record button click
  const onRecordClick = useCallback(() => {
    recorder.isRecording() ? recorder.stopRecording() : recorder.startRecording()
  }, [recorder])

  // On reset button click
  const onResetClick = useCallback(() => {
    if(!canPlay) return
    wavesurfer.stop()
    wavesurfer.empty()
    // Controller
    setCanPlay(false)
    setIsPlaying(false)
    setIsRecording(false)
    // Time
    setPlayTime(0.0)
    setRecordTime(0.0)
    setAudioDuration(0.0)
    // Callback
    props.onRecordComplete()
  }, [props, wavesurfer, canPlay])

  //-------------------------------------------------------------------------------------------

  // Subscribe events
  useEffect(() => {
    if (!isRecorderEnabled || !recorder) return

    // Set defaults
    setCanPlay(false)
    setIsRecording(false)
    setRecordTime(0.0)
    
    const subscriptions = [
      recorder.on('record-start', () => {
        setCanPlay(false)
        setIsRecording(true)
      }),
      recorder.on('record-end', (blob) => {
        setCanPlay(true)
        setIsRecording(false)
        props.onRecordComplete(URL.createObjectURL(blob))
      }),
      recorder.on('record-progress', (duration) => {
        setRecordTime(duration / 1000)
      })
    ]

    return () => {
      try{ subscriptions.forEach((unsub) => unsub()) }
      catch(e) { /* pass */ }
    }

  }, [props, recorder, isRecorderEnabled])


  // Render // -----------------------------------------------------------------------------
  return (
      <WavePlayerContainer>

          <ControlButtons 
            canPlay={canPlay} recorderEnabled={isRecorderEnabled} 
            isPlaying={isPlaying} isRecording={isRecording}
            onPlayClick={onPlayClick} onRecordClick={onRecordClick} onResetClick={onResetClick}
          >
              <WaveformContainer ref={containerRef}/>
              
              <TimeDisplay 
                currentTime={canPlay ? playTime : recordTime}
                totalTime={canPlay ? audioDuration : undefined} 
              />
          
          </ControlButtons>

      </WavePlayerContainer>
  )

}

export default WaveSurferPlayer;