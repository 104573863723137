import React, { useState, useEffect, useRef } from "react";

import {
    Button,
    Card,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Alert,
  } from "reactstrap";

import { request_user_update } from "services/Services";
import useUser from "components/Authentication/useUser";

import ChangePassword from "./ChangePassword";
import UpdateProfile from "./UpdateProfile";


function UserProfile() {

  const {user} = useUser();

  const [username, setUsername] = useState(null)
  const [email, setEmail] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState(null)
  const canChangePassword = useRef(true)

  const [currentPassword, setCurrentPassword] = useState(null)

  const [isPasswordWrong, setIsPasswordWrong] = useState(false)
  const [isUsernameExist, setIsUsernameExist] = useState(false)
  const [isEmailExist, setIsEmailExist] = useState(false)

  const [isUpdated, setIsUpdated] = useState(false)

  useEffect(() => {
    if(user === null) return

    setUsername(user['username'])
    setEmail(user['email'])
    setFirstName(user['firstname'])
    setLastName(user['lastname'])
  }, [user]);

  const onSucceed = () => {
    setIsPasswordWrong(false);
    setIsUsernameExist(false);
    setIsEmailExist(false);
    setIsUpdated(true);
  }
  
  const onFail = (statusCode, detail) => {
    setIsUpdated(false);

    if(statusCode === 403) {
      setIsPasswordWrong(true);
    }
    else if(statusCode === 409) {
      if(detail.toLowerCase().includes('username'))
        setIsUsernameExist(true);
      else if(detail.toLowerCase().includes('e-mail'))
        setIsEmailExist(true);
    }

  }

  const onSave = async e => {
    e.preventDefault();

    if(!canChangePassword.current) {
      console.log("passwords cannot be changed!")
      return;
    }

    // Correct password
    var password = null;
    if(newPassword)
      password = newPassword;
    
    request_user_update( {
      data: { 'current_password': currentPassword,
              
              'email': email,
              'username': username,
              'password': password,
              'firstname': firstName,
              'lastname': lastName
            },
      onSucceed: onSucceed,
      onFail: onFail
    });
    
  }


    return (

      <Card>

        <Form onSubmit={onSave}>

          <UpdateProfile 
            username={username}
            setUsername={setUsername}
            email={email}
            setEmail={setEmail}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            isUsernameExist={isUsernameExist}
            setIsUsernameExist={setIsUsernameExist}
            isEmailExist={isEmailExist}
            setIsEmailExist={setIsEmailExist}
          />

        <ChangePassword 
            newPassword={newPassword} 
            setNewPassword={setNewPassword} 
            confirmPassword={confirmPassword} 
            setConfirmPassword={setConfirmPassword}
            canChangePassword={canChangePassword}
          />
      
            <CardFooter>
                <Row>
                    <Col md="6"></Col>
                    <Col className="pl-md-1" md="3">
                      <FormGroup>
                        <label>Current Password</label>
                        <Input
                          placeholder="Current Password"
                          type="password"
                          onChange={evt => setCurrentPassword(evt.target.value)}
                          required={true}
                        />
                        {isPasswordWrong && <p className="text-danger" style={{fontSize:11}}>Wrong password!</p>}
                      </FormGroup>
                    </Col>
                    
                    <Col className="pl-md-1" md="3">
                        <Button type="submit" className="btn-fill" 
                          color="primary" style={{"margin": "24px 0px"}} >
                            Save
                        </Button>
                    </Col>
                </Row>

                <Alert color="success" isOpen={isUpdated} toggle={() => setIsUpdated(false)}>
                    <span>
                      <b>Success - </b>
                      User profile updated!
                    </span>
                </Alert>
            </CardFooter> 

        </Form>

      </Card>

    )

}

export default UserProfile;