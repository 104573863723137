import React from "react";

import ContentEditable from 'react-contenteditable'

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Label,
    Input,
  } from "reactstrap";

import { request_media_summary } from "services/Services";

  // Import React hooks
const { useState, useEffect } = React


function Summary(props) {

    const media = props.media

    const [hasSummary, setHasSummary] = useState(false);
    const [isSummaryRunning, setIsSummaryRunning] = useState(media['status']['summary'] === 'running');

    useEffect(() => {
        try {
            setHasSummary(media['summary'] != null);
        } catch (error) {
            setHasSummary(false);
        }
    }, [media])

    const onSummaryClick = () => {

        request_media_summary({
            data: { 'media_id': media['_id']},
            onAccept:  (() => { setIsSummaryRunning(true) })
        })

    }

    
    return (  
        <>

            <Card>
                <CardHeader>
                    <CardTitle tag="h3" style={{padding: "0px 15px 0px"}}>Media Summary</CardTitle>
                </CardHeader>
            
                <CardBody>
                        
                        {isSummaryRunning 
                            ? <>
                                <p>Summary Running</p>
                            </>
                            : 
                            <>
                                {hasSummary ? <>
                                    
                                    <CardHeader>English Summary</CardHeader>
                                    <CardBody>
                                        <ContentEditable
                                            className={`segment normal`}
                                            html={media['summary']['english'].replaceAll('\n', '<br>')}      // innerHTML of the editable div
                                            disabled={true}       // use true to disable editing
                                        />
                                    </CardBody>

                                    <CardHeader>Turkish Summary</CardHeader>
                                    <CardBody>
                                        <ContentEditable
                                            className={`segment normal`}
                                            html={media['summary']['turkish'].replaceAll('\n', '<br>')}      // innerHTML of the editable div
                                            disabled={true}       // use true to disable editing
                                        />
                                    </CardBody>

                                </>: 
                                    <Button color="primary" onClick={onSummaryClick}>
                                        Request Transcript Summary!
                                    </Button>
                                
                                }

                            </>
                            
                        }
                        
                </CardBody>
            
            </Card>

                
        </>
    );
}

export default Summary;