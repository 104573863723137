import React from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Input,
    Alert,
} from "reactstrap";

import { AudioUpload } from "./AudioUpload";
import { AudioRecord } from "./AudioRecord";
import { request_transcribe } from "services/Services";

// Import React hooks
const { useRef, useState } = React



function MediaUpload (props) {

    const [isUpload, setIsUpload] = useState(true)

    const [language, setLanguage] = useState("Turkish")

    const audioRef = useRef();
    const [transcribeDisabled, setTranscribeDisabled] = useState(false)

    const [resetComponent, setResetComponent] = useState(true)

    const [isAudioMissing, setIsAudioMissing] = useState(false)

    const [isStereoDialogue, setIsStereoDialogue] = useState(false)

    const onTranscribeClick = () => {

        if(!audioRef.current) {
            console.log("Upload or record audio first!")
            setIsAudioMissing(true)
            return;
        }
        setTranscribeDisabled(true);

        audioRef.current['language'] = language;
        audioRef.current['multi_speaker'] = isStereoDialogue && isUpload;

        request_transcribe({
            data: audioRef.current,
            onResponse: (() => {
                setTranscribeDisabled(false);
                setResetComponent(state => !state);
            }),
            onError: (() => {
                setTranscribeDisabled(false);
            }),
            onAccept: (() => {props.setNewTranscription(value => value + 1)})
        })

    }

    return (

        <Card style={{padding: "20px"}}>

            <Card className="card-plain">
                <CardHeader role="tab">
                <a
                    aria-expanded={isUpload}
                    href="#pablo"
                    data-parent="#accordion"
                    data-toggle="collapse"
                    onClick={(e) => {e.preventDefault(); setIsUpload(true)}}
                >
                    Upload media{" "}
                    <i className="tim-icons icon-minimal-down" />
                </a>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={isUpload}>
                    <CardBody>
                        <AudioUpload audioRef={audioRef} reset={resetComponent} />   
                        <div style={{paddingLeft:"20px"}}>
                            <Input type="checkbox" onChange={() => setIsStereoDialogue((state) => !state)}/> {' '} Stereo Dialogue?
                        </div>                     
                    </CardBody>
                </Collapse>
            </Card>

            {/* <Card className="card-plain">
                <CardHeader role="tab">
                <a
                    aria-expanded={!isUpload}
                    href="#pablo"
                    data-parent="#accordion"
                    data-toggle="collapse"
                    onClick={(e) => {e.preventDefault(); setIsUpload(false)}}
                >
                    Record audio{" "}
                    <i className="tim-icons icon-minimal-down" />
                </a>
                </CardHeader>
                <Collapse role="tabpanel" isOpen={!isUpload}>
                    <CardBody>
                        <AudioRecord audioRef={audioRef} />
                    </CardBody>
                </Collapse>
            </Card> */}

            <Input type="select" name="select" id="language" onChange={(event) => setLanguage(event.target.value)} >
                <option>Turkish</option>
                <option>English</option>
                <option>German</option>
                <option>Spanish</option>
                <option>French</option>
                <option>Arabic</option>
                <option>Hebrew</option>
                <option>Bambara (experimental)</option>
            </Input>
            
            <br></br>
            <Button color="primary" onClick={onTranscribeClick} disabled={transcribeDisabled}>
                Transcribe!
            </Button>

            <Alert color="primary" isOpen={isAudioMissing} toggle={() => setIsAudioMissing(false)}>
                <span>
                    Upload or record audio first!
                </span>
            </Alert>
      
        </Card>
      
    )
}


export default MediaUpload;