import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import './style.css';

import { request_user_login } from 'services/Services';
import useToken from "components/Authentication/useToken";

import logo from "assets/img/favicon.png";

export default function Login() {

  const navigate = useNavigate();
  const { setToken } = useToken();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [userNotFound, setUserNotFound] = useState(false)

  const loginHandler = async e => {
    e.preventDefault();

    request_user_login({
      data: { username: username,
              password: password
            },
      onSucceed: (json => {
        setToken(json['access-token']);
        navigate("/media");
      }),
      onFail: (statusCode, detail) => {
        setUserNotFound(true);
      }
    })
  }

  return(

    <div className="login-container">

      <Row style={{justifyContent:"flex-start"}}>
        <Col className='centered-col'>
          <img src={logo} alt="" style={{height:"200px"}}/>
          <h1 style={{fontSize:"400%"}}> KIWILET </h1> 
        </Col>

        <Col>
          <Card style={{background:"#27293d80"}}>
            <CardBody>
              <Form onSubmit={loginHandler}>
                <FormGroup className="pb-2 mr-sm-2 mb-sm-0">
                  <Label for="exampleUsername" className="mr-sm-2">
                    Username
                  </Label>
                  <Input
                    type="text"
                    name="username"
                    id="exampleUsername"
                    placeholder="username"
                    onChange={(ev) => {setUsername(ev.currentTarget.value); setUserNotFound(false)}}
                    required={true}
                  />
                </FormGroup>
                <FormGroup className="pb-2 mr-sm-2 mb-sm-0">
                  <Label for="examplePassword" className="mr-sm-2">
                    Password
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    id="examplePassword"
                    placeholder="********"
                    onChange={(ev) => {setPassword(ev.currentTarget.value); setUserNotFound(false)}}
                    required={true}
                  />
                </FormGroup>
                {userNotFound && <p className="text-warning" style={{fontSize:11}}>Username or password were incorrect!</p>}
                <Button type="submit" color="primary">
                  Login
                </Button>
              </Form>
            </CardBody>
          </Card>

          <p className="text-muted"> by 
            <a href="https://auralet.io/" target="_blank"> Auralet ICT </a> 
          </p> 
          <p>INNOVATE. CONNECT. INSPIRE.</p>

        </Col>
      </Row>

    </div>
    
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};