import { request, request_inference, request_file } from "./Request";



// User Services
export function request_user_login({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('user/login', data, onSucceed, onFail, onError);
}

export function request_user_info({ onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('user/info', null, onSucceed, onFail, onError);
}

export function request_user_update ({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('user/update', data, onSucceed, onFail, onError);
}

// Inference Services
export function request_transcribe({data, onResponse = undefined, onAccept = undefined, onFail = undefined, onError = undefined } = {}) {
    request_inference('transcribe', data, onResponse, onAccept, onFail, onError);
}

export function request_translate({data,  onResponse = undefined, onAccept = undefined, onFail = undefined, onError = undefined } = {}) {
    request_inference('translate', data, onResponse, onAccept, onFail, onError);
}

// Media Services
export function request_media_list({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('media/list', data, onSucceed, onFail, onError);
}

export function request_media_detail({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('media/detail', data, onSucceed, onFail, onError);
}

export function request_media_segment({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('media/segment', data, onSucceed, onFail, onError);
}

export function request_media_audio({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request_file('media/audio', data, onSucceed, onFail, onError, data['media_id']);
}

export function request_media_delete({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('media/delete', data, onSucceed, onFail, onError, 'DELETE');
}

export function request_media_update({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('media/update', data, onSucceed, onFail, onError);
}

// Export Services
export function request_pdf({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request_file('export/pdf', data, onSucceed, onFail, onError);
}

// Word Services
export function request_word_list({onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('words/list', null, onSucceed, onFail, onError);
}

export function request_word_update({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('words/update', data, onSucceed, onFail, onError);
}

// Analytics

export function request_analytics({data, onSucceed = undefined, onFail = undefined, onError = undefined } = {}) {
    request('analytics', data, onSucceed, onFail, onError);
}

export function request_analysis_report({data,  onResponse = undefined, onAccept = undefined, onFail = undefined, onError = undefined } = {}) {
    request_inference('media/analysis', data, onResponse, onAccept, onFail, onError);
}

export function request_media_summary({data,  onResponse = undefined, onAccept = undefined, onFail = undefined, onError = undefined } = {}) {
    request_inference('media/summary', data, onResponse, onAccept, onFail, onError);
}
