import React from "react";

// Import React hooks
const { useRef, useState, useEffect, useCallback } = React


const ContextMenu = ({name, url, containerRef }) => {
    const contextMenuRef = useRef();
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    // Context menu event handler
    const handleContextMenu = useCallback((event) => {
        if(url) {
            event.preventDefault(); // Prevent the default context menu
            setShowContextMenu(true);
            setContextMenuPosition({ x: event.x, y: event.y });
        }
    }, [url]);

    // Attach and detach the context menu event listener
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("contextmenu", handleContextMenu);
        }
        return () => {
            if (container) {
                container.removeEventListener("contextmenu", handleContextMenu);
            }
        };
    }, [containerRef, handleContextMenu]);

    useEffect(() => {
        // Handler to call on click
        const handleClickOutside = (event) => {
          if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
            setShowContextMenu(false);
          }
        };
    
        // Adding click event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          // Removing click event listener on cleanup
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [contextMenuRef]);


    // Function to map common MIME types to file extensions
    const extensionFromMimeType = (mimeType) => {
        const mapping = {
          'audio/wav': 'wav',
          'audio/mp3': 'mp3',
          'audio/mpeg': 'mp3', // Often used for MP3 files
          'audio/x-m4a': 'm4a',
          'audio/m4a': 'm4a',
          'audio/ogg': 'ogg',
          'audio/flac': 'flac',
          'audio/x-flac': 'flac',
          'audio/webm': 'webm',
          'video/webm': 'webm', // WebM could be both audio and video
        };
        return mapping[mimeType] || 'audio';
      };

    // Download action
    const handleDownload = useCallback(() => {
        setShowContextMenu(false); // Hide the context menu
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const mimeType = blob.type;
                const fileExtension = extensionFromMimeType(mimeType);
                const filename = `${name || 'audio'}.${fileExtension}`; // Construct the filename with the correct extension

                // Create and trigger the download link
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href); // Clean up by revoking the object URL
            })
            .catch(error => console.error('Error fetching the blob:', error));
    }, [name, url]);


    return (
        <>
        {showContextMenu &&
            <button ref={contextMenuRef}
                style={{
                    position: 'fixed',
                    top: contextMenuPosition.y,
                    left: contextMenuPosition.x,
                    zIndex: 1000,
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    padding: '10px'
                }}
                onClick={handleDownload}
            >
                Download
            </button>
        }
        </>
      
    );
  };

export default ContextMenu;