import styled from "styled-components";

export const WavePlayerContainer = styled.div`
  display: flex;
  // flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  background: transparent;
  gap: 0.8rem;
`;

export const WaveformContainer = styled.div`
  width: 100%;
  height: 90px;
`;


export const IconWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px; 
`;


export const IconStyle = { 
  color: "rgb(196, 196, 196)", 
  fontSize: "2.4em",
  position: 'absolute',
  transition: 'opacity 0.3s ease'
};


export const TimeDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; // Center vertically
  align-items: center; // Center horizontally
  height: 100px; // Adjust based on your UI needs
  position: relative;
  cursor: default;
  ${({ enablehover }) => enablehover && `
    &:hover .currentTime {
      transform: translateY(-28px); // Move up when hovered
      transition: transform 0.3s ease;
    }
    &:hover .hideable {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s ease, visibility 0.3s ease;
      transform: translateY(12px); // Move up when hovered
      transition: transform 0.3s ease;
    }
  `}
`;

export const TimeText = styled.div`
  color: rgb(196, 196, 196);
  text-align: center; 
  font-size: 1.2em;
  width: 8ch;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 0;
  visibility: hidden;
  &.alwaysShow {
    opacity: 1;
    visibility: visible;
    position: absolute;
    top: 50%; // Start in the center
    transform: translateY(-50%); // Adjust for exact centering
    transition: transform 0.3s ease; // Smooth transition for moving up
  }
`;

export const Separator = styled.div`
  border-bottom: 1px solid rgb(72, 72, 72);
  width: 80%;
  margin: 4px 0;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 0;
  visibility: hidden;
`;

export const MonospaceCharacter = styled.span`
  display: inline-block;
  width: 0.6em; // Adjust this width until it visually matches the width of a character in your chosen font at the current size.
  text-align: center;
`;