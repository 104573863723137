import React from "react";


import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Table,
    Label,
    Input,
  } from "reactstrap";

import { request_analysis_report } from "services/Services";

  // Import React hooks
const { useState, useEffect } = React

function Emotion(props) {
    const emotion = props.emotion;
    const emotionText = emotion?.charAt(0).toUpperCase() + emotion?.slice(1)
    let textClass = 'text-success';
    switch(emotion) {
        case 'angry':
          textClass = 'text-danger'
          break;
        case 'sad':
            textClass = 'text-warning'
          break;
        case 'excited':
            textClass = 'text-info'
          break;
      }

    return (
        <>
        {emotion &&
            <CardTitle tag="h4">
                <i className="tim-icons icon-satisfied" style={{marginTop:"-5px"}}/> Emotion: {" "} {
                    <span className={textClass}> {emotionText}</span>
                }
            </CardTitle>
        }
        </>
    )
}


function PromptTable(props) {
    const prompts = props.prompts;
    return (
        <>
            <Table>
                <thead>
                    <tr><th>Prompt</th><th>Score</th></tr>
                </thead>
                <tbody>
                    {prompts.map((prompt, index)=>{
                        return <tr key={index}>
                            <td>{prompt['prompt']}</td>
                            <td>{prompt['score']}</td>
                            </tr>
                        })}
                </tbody>
            </Table>
        </>
    )

}


function Analysis(props) {

    const media = props.media
    
    const [queryPrompt, setQueryPrompt] = useState('');

    const [analyzeDisabled, setAnalyzeDisabled] = useState(false);
    const [hasAnalysis, setHasAnalysis] = useState(false);
    const [isAnalysisRunning, setIsAnalysisRunning] = useState(media['status']['analysis'] === 'running');

    useEffect(() => {
        try {
            setHasAnalysis(media['report'] != null);
        } catch (error) {
            setHasAnalysis(false);
        }

        if(media['report'] != null) {
            let prompts = [];
            media['report']['prompts'].forEach((prompt) => { 
                prompts.push(prompt['prompt']);
            })
            setQueryPrompt(prompts.join("\n"));
        }
        
    }, [media])

    useEffect(() => {

        if(!queryPrompt) {
            setAnalyzeDisabled(true);
        }
        else if(media['report'] != null) {
            let prompts = [];
            media['report']['prompts'].forEach((prompt) => { 
                prompts.push(prompt['prompt']);
            })
            setAnalyzeDisabled(prompts.join("\n") === queryPrompt)
        }
        else {
            setAnalyzeDisabled(false);
        }

    }, [media, queryPrompt])


    const onAnalyseClick = () => {

        request_analysis_report({
            data: { 'media_id': media['_id'],
                    'prompt' : queryPrompt
                  },
            onAccept:  (() => { setIsAnalysisRunning(true) })
        })

    }

    
    return (  
        <>

            <Card>
                <CardHeader>
                    <CardTitle tag="h3" style={{padding: "0px 15px 0px"}}>Analysis Report</CardTitle>
                </CardHeader>
            
                <CardBody>
                        
                        {isAnalysisRunning 
                            ? <>
                                <p>Analysis Running</p>
                            </>
                            : 
                            <>
                                {hasAnalysis && <>
                                    <Emotion emotion={media['report']['emotion']}/>

                                    <PromptTable prompts={media['report']['prompts']} />
                                </>}

                                <Label className="mr-sm-2">
                                    Search prompt, enter line-by-line sentences that must-have in transcript.
                                </Label>
                                <Input id="exampleText" name="text" type="textarea"
                                    value={queryPrompt}
                                    onChange={(ev) => {setQueryPrompt(ev.currentTarget.value);}}
                                />

                                <Button color="primary" onClick={onAnalyseClick} disabled={analyzeDisabled}>
                                    Request Transcript Analysis!
                                </Button>

                            </>
                            
                        }
                        
                </CardBody>
            
            </Card>

                
        </>
    );
}

export default Analysis;