import { useState } from 'react';

export default function useToken() {
  
  const getToken = () => {
    return localStorage.getItem('access-token');
  };

  const saveToken = token => {
    localStorage.setItem('access-token', token);
    setToken(token);
  };

  const [token, setToken] = useState(getToken());

  return {
    setToken: saveToken,
    token
  }

}

export function accessToken() {
  return localStorage.getItem('access-token');
}

export function removeToken() {
  localStorage.removeItem('access-token');
}