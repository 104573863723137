import { accessToken } from "components/Authentication/useToken";

import { setData, getData } from "services/DataCache"

export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:8081/";

export async function request (path, data, onSucceed, onFail, onError, method='POST') {
    
    const token = accessToken();

    var isOk = false;
    var statusCode = null;
    
    fetch(API_URL + path, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    })
    .then(response =>  {
        isOk = response.ok;
        statusCode = response.status;
        return response.json();
    })
    .then(json => {
        if(isOk)
            onSucceed?.(json);
        else
            onFail?.(statusCode, json.detail);
    })
    .catch(error => {
        onError?.(error);
    });

}

export async function request_inference (path, data, onResponse, onAccept, onFail, onError) {
    
    const token = accessToken();
    
    fetch(API_URL + path, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    })
    .then(response =>  {
        onResponse?.();
        if(response.ok)
            onAccept?.();
        else
            onFail?.();
    })
    .catch(error => {
        onError?.(error);
    });

}


export async function request_file (path, data, onSucceed, onFail, onError, cacheKey=undefined) {

    if(cacheKey) {
        try {
            const cacheData = await getData(cacheKey);
            // const cacheData = sessionStorage.getItem(cacheKey);
            if(cacheData) {
                const fileURL = URL.createObjectURL(cacheData)
                onSucceed?.(fileURL);
                return;
            }
        } catch (error) {
            console.error('Error getting data:', error);
            return null;
        }
    }
    
    const token = accessToken();

    var isOk = false;
    var statusCode = null;
    
    fetch(API_URL + path, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    })
    .then(response =>  {
        isOk = response.ok;
        statusCode = response.status;
        if(isOk)
            return response.blob();
        else
            return response.json();
    })
    .then(data => {
        if(isOk) {
            const fileURL = URL.createObjectURL(data);
            onSucceed?.(fileURL);
            if(cacheKey) {
                // Set data
                // sessionStorage.setItem(cacheKey, data);
                setData(cacheKey, data)
                    .then(() => console.log('Data set successfully'))
                    .catch(error => console.error('Error setting data:', error));
            }
        } 
        else
            onFail?.(statusCode, data.detail);
    })
    .catch(error => {
        onError?.(error);
    });
}
