import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import useToken from './useToken';

import { request_user_info } from "services/Services";
import { removeToken } from 'components/Authentication/useToken';
import { deleteDatabase } from 'services/DataCache';

export default function useUser() {
    const navigate = useNavigate();

    const { setToken } = useToken();

    const [user, setUser] = useState(null);

    const onSucceed = (response) => {
        setToken(response['access-token']);
        delete response['access-token'];
        setUser(response);
    };

    const onFail = () => {
        removeToken();
        setUser(null);
        sessionStorage.clear();
        deleteDatabase();
        navigate("/login");
    }

    useEffect(() => {
        request_user_info({
            onSucceed: onSucceed,
            onFail: onFail
        })
    }, []);

    return {
        setUser,
        user
    }

}