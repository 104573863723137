import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

import ReactQuill from 'react-quill';
import "./quill.snow.css"

import useUser from "components/Authentication/useUser";

import { saveAs } from 'file-saver';
import { request_media_detail, request_pdf } from 'services/Services';

import toolbar from './Toolbar';
import templateMap from './Templates';


const { useState, useEffect } = React

function Editor({mediaID, language=null, canExport=true}) {

    const {user} = useUser();

    const [modal, setModal] = useState(false);

    const [media, setMedia] = useState(null)

    const [template, setTemplate] = useState('Plain');

    const [content, setContent] = useState("");
    const [exportDisabled, setExportDisabled] = useState(false)


    const toggle = () => {
        setModal(modal => !modal);
    }

    const applyTemplate = () => {

        let document = templateMap.get(template).asDocument(media, language, user)
        setContent(document);
    }

    useEffect(() => {
        if(!modal) 
            return;

        request_media_detail({
            data : { 'media_id': mediaID },
            onSucceed: setMedia
        }) 
    }, [modal, mediaID]);


    useEffect(() => {
        if(!media) 
            return;

        applyTemplate() 
    }, [media, template]);


    async function requestPDF() {
        setExportDisabled(true)

        request_pdf({
            data: { 'html': content },
            onSucceed: (url => {
                saveAs(url, media['name'] + '.pdf');
                setExportDisabled(false);
                setModal(false);
            })
        })
    }

    return (  
        <>
            
            <Button color="primary" onClick={toggle} disabled={!canExport}>Export</Button>
        
            <Modal isOpen={modal} toggle={toggle} size="xl" style={{height: '200px'}}>
                <ModalHeader toggle={toggle}>Export PDF</ModalHeader>
                <ModalBody>

                    <Input type="select" name="select" id="template" defaultValue={template}
                        style={{color:"black", margin:"0px 0px 10px 0px"}}
                        onChange={(event) => setTemplate(event.target.value)} >
                        {Array.from( templateMap ).map(([key, value]) => { 
                                return <option key={key}>{key}</option> 
                            })}
                    </Input>
                    
                    <ReactQuill 
                        theme="snow" 
                        value={content} 
                        onChange={setContent}
                        modules={{toolbar: toolbar}}
                        preserveWhitespace={true}
                    />

                </ModalBody>
                <ModalFooter style={{padding: "20px", marginLeft:"auto", marginRight:0}}>
                    <Button color="primary" onClick={requestPDF} disabled={exportDisabled}>Export</Button>
                </ModalFooter>
            </Modal>

        </>
    );
}

export default Editor;