import React from "react";

import Segment from "./Segment";

import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button
  } from "reactstrap";


import DropdownComponent from "components/Dropdown/Dropdown";
import Editor from "components/Editor/Editor";
import { request_translate } from "services/Services";


  // Import React hooks
const { useState, useEffect } = React



function Translation(props) {

    const media = props.media
    const playerReference = props.playerReference

    const options = ['Turkish', 'English'].filter(item => item.toLowerCase() !== media['transcript']['language'].toLowerCase())


    const [language, setLanguage] = useState(options[0])
    const [hasTranslation, setHasTranslation] = useState(false)
    const [isTranslationRunning, setIsTranslationRunning] = useState(media['status']['translate'] === 'running');

    useEffect(() => {
        try {
            setHasTranslation(media['transcript']['segments'][0]['translation'][language.toLowerCase()] != null);
        } catch (error) {
            setHasTranslation(false);
        }
        
    }, [media, language])


    const onTranslateClick = () => {

        request_translate({
            data: { 'media_id': media['_id'],
                    'language' : language.toLowerCase()
                  },
            onAccept:  (() => { setIsTranslationRunning(true) })
        })

    }

    
    return (  
        <>

            <Card>
                <CardHeader>
                    <Row style={{justifyContent:"space-between"}}>
                        <Col>
                            <Row style={{justifyContent:"flex-start"}}>
                                <CardTitle tag="h3" style={{padding: "0px 15px 0px"}}>Translation</CardTitle>
                                <DropdownComponent options={options} setSelection={setLanguage}></DropdownComponent>
                            </Row>
                        </Col>
                        <Col style={{display:"flex", justifyContent:"flex-end"}}>
                            <Editor mediaID={media['_id']} language={language} canExport={hasTranslation}></Editor>
                        </Col>
                    </Row> 
                </CardHeader>
            
                <CardBody>
                        
                        {isTranslationRunning 
                            ? <>
                                <p>Translation Running</p>
                            </>
                            : hasTranslation 
                            ? <>
                                {media['transcript']['segments'].map((segment, index)=>{
                                    return <Segment key={index + media['transcript']['segments'].length} 
                                        media={media['_id']}
                                        segment={segment} 
                                        language={language}
                                        playerReference={playerReference}/>
                                    })}
                            </>
                            : <>
                                <Button color="primary" onClick={onTranslateClick}>
                                    Translate!
                                </Button>
                            </>

                        }

                        
                </CardBody>
            
            </Card>

                
        </>
    );
}

export default Translation;