import React from "react";

import { FileUploader } from "react-drag-drop-files";

import "./style.css";

import {
    Card
} from "reactstrap";

const { useState, useEffect } = React

export function AudioUpload(props) {

    const fileTypes = ["WAV", "MP3", "M4A", "OGG", "FLAC", "WEBM"];

    const [file, setFile] = useState(null)

    const onUploadComplete = (file) => {
        setFile(file)

        var reader = new FileReader();
        reader.readAsDataURL(file); 
        reader.onloadend = function() {
            props.audioRef.current = {
                name: file.name,
                audio: reader.result.split('base64,')[1]
                // https://stackoverflow.com/questions/24289182/how-to-strip-type-from-javascript-filereader-base64-string
            }
        }
        
    }

    useEffect(() => {
        setFile(null)
    }, [props.reset]);
  
    return (
        <Card>
            <FileUploader
                handleChange={onUploadComplete} 
                name="file" 
                types={fileTypes} 
                multiple={false}
                >
                    
                <div className="upload-container" > 
                    <i className="tim-icons icon-cloud-upload-94" style={{"fontSize": "42px", "flex": "20%", "padding": "3px 0px"}}/> 
                    
                    <div style={{"flex": "80%"}}>

                        {file 
                            ? <>
                                <span style={{"fontSize": "20px"}}>
                                    {file.name}
                                </span>
                            </>
                            : <>
                                <span style={{"fontSize": "16px"}}> <u>Upload</u> or drop a file right here </span> <br></br>
                                <span title={"types: " + fileTypes.join(",")} className="file-types" style={{"fontSize": "12px"}}>{fileTypes.join(",")}</span>      
                            </>
                        }
                    </div>
                </div>
            </FileUploader>
        </Card>
    );
}
