import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import {BsFillCaretDownFill, BsFillCaretUpFill} from 'react-icons/bs'

import "./style.css";

export default class DropdownComponent extends React.Component {
  constructor(props) {
    super(props);

    this.setSelection = props.setSelection;
    this.options = props.options;
    this.toggle = this.toggle.bind(this);
    this.onSelection = this.onSelection.bind(this);
    this.state = {
      selection: this.options[0],
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onSelection(event) {
    this.setSelection(event.currentTarget.textContent);
    this.setState({selection: event.currentTarget.textContent}); 
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle className='dropdown-tittle'>
          {this.state.selection} 

          {this.state.dropdownOpen 
            ? <BsFillCaretUpFill style={{marginLeft:'4px'}}/> 
            : <BsFillCaretDownFill style={{marginLeft:'4px'}}/>}
        
        </DropdownToggle>
        <DropdownMenu>
            {this.options.map((option, index)=>{
                return <DropdownItem 
                        key={index} 
                        onClick={this.onSelection}>
                            {option}
                    </DropdownItem>})}
   
        </DropdownMenu>
      </Dropdown>
    );
  }
}