import React from 'react';

import Login from "components/Login/Login";
import useUser from "components/Authentication/useUser";
import { removeToken } from 'components/Authentication/useToken';
import { deleteDatabase } from 'services/DataCache';

function LoginPage(props) {

    const {user, setUser} = useUser();

    const logout = () => {
      removeToken();
      setUser(null);
      sessionStorage.clear();
      deleteDatabase();
    }

    return (  
        <div className="content">

            { user 
              ? <>
                {logout()}
              </>
              : <Login />
            }
            
        </div>
    );
}

export default LoginPage;